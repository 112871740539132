exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-dialog-contact-tsx": () => import("./../../../src/pages/dialog-contact.tsx" /* webpackChunkName: "component---src-pages-dialog-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-methodology-tsx": () => import("./../../../src/pages/methodology.tsx" /* webpackChunkName: "component---src-pages-methodology-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-products-consumer-tsx": () => import("./../../../src/pages/products/consumer.tsx" /* webpackChunkName: "component---src-pages-products-consumer-tsx" */),
  "component---src-pages-products-crypto-tsx": () => import("./../../../src/pages/products/crypto.tsx" /* webpackChunkName: "component---src-pages-products-crypto-tsx" */),
  "component---src-pages-products-gambling-tsx": () => import("./../../../src/pages/products/gambling.tsx" /* webpackChunkName: "component---src-pages-products-gambling-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-products-streaming-tsx": () => import("./../../../src/pages/products/streaming.tsx" /* webpackChunkName: "component---src-pages-products-streaming-tsx" */),
  "component---src-pages-terms-conditions-tsx": () => import("./../../../src/pages/terms-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../../../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */)
}

